import Axios from "axios";

export const GetWxUrl = async (orderNUmber) => {
  let res = await Axios.get(`/wechat/oauth/${orderNUmber}`);
  return res.data;
};

export const PingPlusPayment = async (data) => {
  let res = await Axios.post("/payments/pingplusplus", data, {
    headers: { ClientDeviceType: 4 },
  });
  return res.data;
};
