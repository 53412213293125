import { useEffect } from "react";
import { GetWxUrl, PingPlusPayment } from "./payApi";
import { message } from "antd";
import Pingplusplus from "pingpp-js";
import { isPaySuccess } from "./order/api";
export const WxPayCmp = (props) => {
  let pathname = new URLSearchParams(props.location?.search);
  let clientCode = pathname.get("code");
  useEffect(() => {
    let useCode = localStorage.getItem("usedCode");

    const reload = async () => {
      let res = await GetWxUrl(localStorage.getItem("wxOrderNumber"));
      if (res.code !== 0) {
        message.error(res.message);
      } else {
        window.location.href = res.data.url;
      }
    };
    if (!clientCode || (clientCode && useCode && clientCode === useCode)) {
      reload();
    }
  }, [clientCode]);

  useEffect(() => {
    let useCode = localStorage.getItem("usedCode");
    if (
      (clientCode && useCode && clientCode !== useCode) ||
      (clientCode && !useCode)
    ) {
      const RequestPay = async () => {
        Pingplusplus.setUrlReturnCallback(
          (err, url) => {
            if (!err) {
              setTimeout(() => {
                window.location.href = url;
              }, 300);
            } else {
              throw err;
            }
          },
          ["alipay_wap"]
        );
        let res = await PingPlusPayment({
          orderNumber: localStorage.getItem("wxOrderNumber"),
          payMethod: 6,
          platform: 5,
          clientCode: clientCode,
        });
        if (res.code !== 0) {
          message.error(res.message);
        } else {
          localStorage.setItem("usedCode", clientCode);
          Pingplusplus.createPayment(res.data.charge);
        }
      };
      RequestPay();
    }
  }, [clientCode]);
  const isPaySuccessFn = async (orderNumber) => {
    let res = await isPaySuccess(orderNumber);
    if (res.code === 0) {
      let { data } = res;
      if (data.payStage === 2 || data.payStage === 3) {
        props.history.push("/home/pay_success", {
          code: localStorage.getItem("rememberCode"),
          type: data.type,
          todoList: data.todoList,
        });
      } else {
        message.destroy();
        message.error("订单还未支付,请重新支付");
      }
    } else {
      message.error(res.message);
    }
  };
  return (
    <div>
      <div
        className="bacToCourse"
        onClick={() => {
          let pushCode = localStorage.getItem("rememberCode");
          if (pushCode.startsWith("PKG")) {
            props.history.push(`/home/coursepack/${pushCode}`);
          } else {
            props.history.push(`/home/course/${pushCode}`);
          }
        }}
      >
        <img
          src={require("./../public/arrow-left-s-fill.png")}
          width={"24px"}
          height={"24px"}
          style={{ objectFit: "contain" }}
        />
        <div className="backCourseText">返回课程详情</div>
      </div>
      <div className="payQuesitionBox">
        <div className="flex_center">
          <div>支付完成前，请不要关闭此页面</div>
          <div>支付完成后，请根据您的支付情况点击下方按钮</div>
        </div>
        <div className="flex_between">
          <div
            className="empty_bt"
            onClick={() => props.history.push("/home/contact")}
          >
            遇到问题
          </div>
          <div
            className="active_bt"
            onClick={() => {
              isPaySuccessFn(localStorage.getItem("wxOrderNumber"));
            }}
          >
            支付成功
          </div>
        </div>
      </div>
    </div>
  );
};
