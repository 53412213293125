import Axios from "axios";

export const getEnrolments = async (orderId) => {
  let res = await Axios.get(`/course_codes/${orderId}`);
  return res.data;
};

export const getOrder = async (json) => {
  let res = await Axios.post("/enrolments", json, {
    headers: { ClientDeviceType: 4 },
  });
  return res.data;
};

export const Courseorders = async (orderNumber) => {
  let res = await Axios.get(`/courseorders/${orderNumber}`);
  return res.data;
};

export const VerificationccTalk = async (json) => {
  let res = await Axios.get(`/oauth/cctalk-token`, { params: json });
  return res.data;
};

export const getCCtalkToken = async () => {
  let res = await Axios.get("/oauth/page/cctalk-token");
  return res.data;
};

export const pingplusplus = async (json) => {
  let res = await Axios.post("/payments/pingplusplus", json, {
    headers: { ClientDeviceType: 4 },
  });
  return res.data;
};

export const Payment = async (json) => {
  let res = await Axios.post("/payments", json, {
    headers: { ClientDeviceType: 4 },
  });
  return res.data;
};

export const isPaySuccess = async (orderNumber) => {
  let res = await Axios.get(`/courseorders/${orderNumber}`, {
    headers: { ClientDeviceType: 4 },
  });
  return res.data;
};

export const cancelEnrolments = async (orderNumber) => {
  let res = await Axios.delete(`/enrolments/${orderNumber}`);
  return res.data;
};

export const getPackDetail = async (code) => {
  let res = await Axios.get(`/course_package_codes/${code}`);
  return res.data;
};
export const getCourseList = async (packageCode, seriesId) => {
  let res = await Axios.get(`/package_courses/${packageCode}/${seriesId}`);
  return res.data;
};
export const payCoursePack = async (data) => {
  let res = await Axios.post(`/course_package_enrolments`, data, {
    headers: { ClientDeviceType: 4 },
  });
  return res.data;
};
export const isweixin = () => {
  let ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  } else {
    return false;
  }
};
