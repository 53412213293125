import { message } from "antd";
import { isPaySuccess } from "./order/api";

export const WxPayResult = (props) => {
  let OrderNum = props.match.params.id || "";
  const isPaySuccessFn = async () => {
    let res = await isPaySuccess(OrderNum);
    if (res.code === 0) {
      let { data } = res;
      if (data.payStage === 2 || data.payStage === 3) {
        props.history.push("/home/pay_success", {
          code: localStorage.getItem("wxrememberCode"),
          type: data.type,
          todoList: data.todoList,
        });
      } else {
        message.destroy();
        message.error("订单还未支付,请重新支付");
      }
    } else {
      message.error(res.message);
    }
  };
  return (
    <div>
      <div
        className="bacToCourse"
        onClick={() => {
          let pushCode = localStorage.getItem("wxrememberCode");
          if (pushCode.startsWith("PKG")) {
            props.history.push(`/home/coursepack/${pushCode}`);
          } else {
            props.history.push(`/home/course/${pushCode}`);
          }
        }}
      >
        <img
          src={require("./../public/arrow-left-s-fill.png")}
          width={"24px"}
          height={"24px"}
          style={{ objectFit: "contain" }}
        />
        <div className="backCourseText">返回课程详情</div>
      </div>
      <div className="payQuesitionBox">
        <div className="flex_center">
          <div>支付完成前，请不要关闭此页面</div>
          <div>支付完成后，请根据您的支付情况点击下方按钮</div>
        </div>
        <div className="flex_between">
          <div
            className="empty_bt"
            onClick={() => props.history.push("/home/contact")}
          >
            遇到问题
          </div>
          <div className="active_bt" onClick={() => isPaySuccessFn()}>
            支付成功
          </div>
        </div>
      </div>
    </div>
  );
};
