import { GoCCFn } from "router/index/bindccCmp";

export const JumpToAuthentic = (props, enrolltext) => {
  if (enrolltext === 15) {
    localStorage.setItem("isContinueEnroll", window.location.href);
    GoCCFn("/home/profile/authorith");
  } else if (enrolltext === 14) {
    GoCCFn();
  } else {
    localStorage.setItem("isContinueEnroll", window.location.href);
    props.history.push("/home/profile/authorith");
  }
};
