import { useEffect } from "react";

export const MiddleForM = (props) => {
  useEffect(() => {
    let pathname = new URLSearchParams(props.location?.search);
    let locationJWT = pathname.get("locationJWT");
    let mOrder = pathname.get("mOrderNum");
    let courseCode = pathname.get("courseCode");
    localStorage.setItem("jwt", locationJWT);
    localStorage.setItem("wxOrderNumber", mOrder);
    localStorage.setItem("rememberCode", courseCode);
    props.history.push("/wxpay");
  }, []);

  return <></>;
};
