import { useState, useMemo, useCallback, useEffect } from "react";

import { message, Icon, Input, Button, Modal } from "antd";

import {
  UserInputComponent,
  PassWordComponent,
  SliderComponent,
  GraphCaptach,
  CaptchaComponentRegist,
  CaptchaComponentLogin,
  MergeCaptachAndGraphCapcha,
} from "./InputMap";
import {
  emailCheck,
  passwordLogincheck,
  NewPhoneCheck,
} from "./../../global_cig";

import { Join, LoginApi, quickLogin, resetPwd, isUserFn } from "./login-api";
import store from "lib/redux/store";
import { enrollFrontUrl } from "devloader";

const Main = (props) => {
  const { setPath } = props;

  const [data, setData] = useState({
    path: props.path,
    captchaValue: "",
    isSend: false,
  });

  useEffect(() => {
    //与子组件的代码重复定义了，这一块主要是在注册时设置密码的清空操作
    setData((state) => ({
      ...state,
      path: props.path,
      captchaValue: "",
      captchaId: "",
      userName: "",
      countryCode: "+86",
      isSend: false,
      hasError: false,
    }));
  }, [props.path]);

  const dispatch = (json) => setData({ ...data, ...json });
  const OnCloseForm = () => {
    setPath("");
  };
  let { path } = data;

  return (
    <div>
      {path === "register" && (
        <RegisetComponent
          title="注册账号"
          {...props}
          dispatch={dispatch}
          onClose={OnCloseForm}
        />
      )}

      {path === "login" && (
        <LoginComponent
          title="登录"
          {...props}
          dispatch={dispatch}
          onClose={OnCloseForm}
        />
      )}

      {path === "forget" && (
        <ForgetComponent
          title="找回密码"
          {...props}
          dispatch={dispatch}
          onClose={OnCloseForm}
        />
      )}

      {path === "setPass" && (
        <SetpasswordComponent
          {...props}
          data={data}
          dispatch={dispatch}
          onClose={OnCloseForm}
        />
      )}

      {path === "registerSetPass" && (
        <SetpasswordComponent
          flag={true}
          {...props}
          data={data}
          dispatch={dispatch}
          onClose={OnCloseForm}
        />
      )}
    </div>
  );
};

export default Main;

const RegisetComponent = (props) => {
  let { dispatch, title } = props;

  const [flag, setFlag] = useState(true);

  const [data, setData] = useState({ countryCode: "+86", state: false });

  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setData({ countryCode: "+86", state: false });
  }, [flag]);

  const onSubmit = async () => {
    let { state, userName, countryCode } = data;
    if (flag && !userName) {
      message.destroy();
      message.error("请输入手机号");
      return;
    }
    if (!flag && !userName) {
      message.destroy();
      message.error("请输入邮箱");
      return;
    }

    if (!state) {
      message.destroy();
      message.error("请滑动验证");
      return;
    }

    setLoading(true);

    if (flag) {
      if (passwordLogincheck(`${countryCode}-${userName}`) === "") {
        setLoading(false);
        message.destroy();
        message.error("手机号格式错误");
      } else {
        let { username } = passwordLogincheck(`${countryCode}-${userName}`);
        let { newName, newCountry } = NewPhoneCheck(countryCode, username);
        let newCheckCountry = newCountry?.startsWith("+")
          ? newCountry
          : `+${newCountry}`;
        let _isUser = await isUserFn({
          userName: `${newCheckCountry}-${newName}`,
        });
        if (_isUser) {
          setLoading(false);
          message.info({
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>该账号已注册</div>
                <div>可直接登录</div>
                <div>正在自动跳转至 登录 页面</div>
              </div>
            ),
            icon: <div></div>,
          });
          setTimeout(() => {
            dispatch({ path: "login" });
          }, 3000);
          return;
        } else {
          dispatch({
            path: "registerSetPass",
            userName: newName,
            countryCode: newCountry,
          });
        }
      }
    } else {
      if (emailCheck(userName)) {
        let _isUser = await isUserFn({ userName });
        if (_isUser) {
          setLoading(false);
          message.destroy();
          message.info({
            content: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div>该账号已注册</div>
                <div>可直接登录</div>
                <div>正在自动跳转至 登录 页面</div>
              </div>
            ),
            icon: <div></div>,
          });
          setTimeout(() => {
            dispatch({ path: "login" });
          }, 3000);
          return;
        } else {
          dispatch({ path: "registerSetPass", userName });
        }
      } else {
        setLoading(false);
        message.destroy();
        message.error("邮箱格式错误");
      }
    }
  };

  const userText = flag ? "请输入手机号码" : "请输入邮箱账户";

  let { state } = data;

  return (
    <>
      <div className="admin_form">
        <Icon
          type="close"
          className="admin_right"
          onClick={() => {
            props.onClose("");
          }}
        />
        <h2 className="admin_from_title">{title}</h2>
        <div className="admin_header">
          <strong
            onClick={() => setFlag(true)}
            className={flag ? "admin_header_active" : ""}
          >
            手机
          </strong>
          <span className="brand"></span>
          <strong
            onClick={() => setFlag(false)}
            className={!flag ? "admin_header_active" : ""}
          >
            邮箱
          </strong>
        </div>
        <div className="login_form">
          <div>
            <UserInputComponent
              onChange={(json) => setData({ ...data, ...json })}
              flag={flag}
              placeholder={userText}
            />
          </div>

          <div style={{ margin: "10px 0" }}>
            {" "}
            <SliderComponent
              state={state}
              onChange={(json) => setData({ ...data, ...json })}
            />{" "}
          </div>

          <Button
            onClick={onSubmit}
            loading={loading}
            style={{
              width: "100%",
              background: "rgba(119,158,185,1)",
              color: "#fff",
            }}
          >
            下一步
          </Button>
          <div
            className="admin_footer"
            onClick={() => {
              dispatch({ path: "login" });
            }}
          >
            已有账号? <span className="font_color_link">前往登录</span>
          </div>
        </div>
      </div>
    </>
  );
};

const LoginComponent = (props) => {
  let { history, isCourse } = props;

  const [data, setData] = useState({
    slider: false,
    loginIndex: 0,
    state: false,
    countryCode: "+86",
    hasError: false,
  });


  const [loading, setLoading] = useState(false);

  const [isPassword, setPassState] = useState(true);

  let { loginIndex } = data;

  let { state, userName, userPwd, slider } = data;
  
  const dispatch = useCallback((json) => setData({ ...data, ...json }), [data]);

  useEffect(() => {
    setData((state) => ({ ...state, userName: "", countryCode: "+86" }));
  }, [isPassword]);

  const capTureSubmit = async () => {
    let { countryCode, captcha, userName } = data;
  
    setLoading(true);

    if (!userName) {
      message.destroy();
      message.error("请输入手机号");
      setLoading(false);
      return;
    }

    if (!captcha) {
      message.destroy();
      message.error("请输入验证码");
      setLoading(false);
      return;
    }

    if (passwordLogincheck(`${countryCode}${userName}`) === "") {
      message.destroy();
      message.error("手机格式错误");
      setLoading(false);
      return;
    }
    let { newName, newCountry } = NewPhoneCheck(countryCode, userName);
    const value = {
      userNameFormat: 1,
      userName: `${newCountry}-${newName}`,
      captcha,
    };

    const res = await quickLogin(value);
    if (res.code !== 0) {
      message.destroy();
      message.error(res.message);
      setLoading(false);
    } else {
      store.dispatch({ type: "delay", paload: { tokendelay: false } });
      setLoading(false);
      message.success("登录成功");
      if (props.isCourse) {
        window.location.href = "";
      } else {
        history.push("/home/mycourses");
      }
    }
  };

  const onSubmit = useCallback(async () => {
    
    if (!userName) {
      message.error("请输入用户名");
      return;
    }
    if (!userPwd) {
      message.error("请输入密码");
      return;
    }
    if (passwordLogincheck(userName) === "") {
      message.destroy();
      message.error("手机号格式不正确");
      return;
    }
    if (slider && !state) {
      message.error("请滑动验证");
      return;
    }

    if (loading) return;

    setLoading(true);

    if (checkFn({ userName })) {
      if (userPwd.length < 6) {
        message.error("用户名或密码错误");
        setLoading(false);
        return;
      }
      let { username, country } = passwordLogincheck(userName);
      let { newName, newCountry } = NewPhoneCheck(country, username);
      const res = await LoginApi({
        userName:
          userName.match(/@/g) !== null
            ? userName
            : `+${newCountry}-${newName}`,
        userPwd,
      });
      if (res.code !== 0) {
        dispatch({ loginIndex: loginIndex + 1 }); //登录失败次数
        setLoading(false);
        message.error(res.message);
      } else {
        store.dispatch({ type: "delay", paload: { tokendelay: false } });
        setLoading(false);
        message.success("登录成功");
        if (isCourse) {
          window.location.href = "";
        } else {
          history.push("/home/mycourses");
        }
      }
    } else {
      setLoading(false);
      message.error("用户名或密码错误");
    }
  }, [
    state,
    userName,
    userPwd,
    slider,
    loginIndex,
    dispatch,
    history,
    loading,
    isCourse,
  ]);

  useEffect(() => {
    document.onkeydown = (e) => {
      if (e.key === "Enter") {
        onSubmit();
      }
    };
    return () => {
      document.onkeydown = null;
    };
  }, [onSubmit]);

  useMemo(() => {
    if (loginIndex >= 2) {
      setData((state) => {
        state.slider = true;
        state.state = false;
        return state;
      });
    }
  }, [loginIndex]);

  return (
    <>
      <div className="admin_form">
        <div className="admin_from_login">
          <div
            className={isPassword ? "admin_from_login_active" : ""}
            onClick={() => {
              setData((state) => {
                state.slider = false;
                state.state = false;
                return state;
              });
              setPassState(true);
            }}
          >
            密码登录
          </div>

          <div
            className={!isPassword ? "admin_from_login_active" : ""}
            onClick={() => {
              setData((state) => {
                state.slider = false;
                state.state = false;
                return state;
              });
              setPassState(false);
            }}
          >
            短信登录
          </div>
        </div>

        <div className="login_form">
          <Icon
            type="close"
            className="admin_right"
            onClick={() => {
              props.onClose("");
            }}
          />

          <div>
            <UserInputComponent
              onChange={dispatch}
              flag={!isPassword}
              placeholder={
                !isPassword ? "请输入手机号" : "请输入手机号码或邮箱"
              }
            />
          </div>
          {!isPassword && data.countryCode !== "+86" ? (
            <MergeCaptachAndGraphCapcha
              userName={userName}
              countryCode={data.countryCode}
              dispatch={dispatch}
              action={8}
              isPhone={true}
            />
          ) : (
            ""
          )}
          {!isPassword && data.countryCode === "+86" ? (
            <div style={{ margin: "20px 0", overflow: "hidden" }}>
              <Input
                type="tel"
                style={{ width: "60%", float: "left" }}
                name="captrue"
                placeholder="请输入短信验证码"
                onChange={({ target: { value } }) =>
                  dispatch({ captcha: value })
                }
              />

              <CaptchaComponentLogin
                action={8}
                userName={userName}
                dispatch={dispatch}
                isPhone={true}
                captchaValue={data.captchaValue}
                captchaId={data.captchaId}
                countryCode={data.countryCode}
                isAutoSendCaptcha={false}
                isNeedGraph={data.countryCode.startsWith("+86") ? false : true}
              />
            </div>
          ) : (
            ""
          )}

          {isPassword && (
            <div style={{ margin: "20px 0" }}>
              <PassWordComponent
                name="password"
                autoComplete="current-password"
                onChange={(value) => dispatch({ userPwd: value })}
                placeholder="请输入密码"
              />
            </div>
          )}

          {data.slider && isPassword && (
            <div style={{ margin: "20px 0" }}>
              <SliderComponent state={data.state} onChange={dispatch} />
            </div>
          )}

          <Button
            onClick={isPassword ? onSubmit : capTureSubmit}
            style={{
              width: "100%",
              background:
                !data.slider || data.state ? "rgba(119,158,185,1)" : "#ccc",
              color: "#fff",
            }}
          >
            {loading ? "登录中..." : "登录"}
          </Button>

          <span
            className="no_password"
            onClick={() => {
              props.dispatch({ path: "forget" });
            }}
          >
            忘记密码?
          </span>

          <div
            className="admin_footer"
            onClick={() => {
              props.dispatch({ path: "register" });
            }}
          >
            没有账号? <span className="font_color_link">前往注册</span>
          </div>
        </div>
      </div>
    </>
  );
};

const ForgetComponent = (props) => {
  let { title } = props;

  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);

  const dispatch = (json) => setData({ ...data, ...json });

  const onSubmit = async () => {
    let { state, userName } = data;
    if (!userName) {
      message.error("请输入用户名");
      return;
    }

    if (!state) {
      message.error("请滑动验证");
      return;
    }
    if (passwordLogincheck(userName) === "") {
      message.destroy();
      message.error("格式错误，请重新输入");
      return;
    }
    let { username, country } = passwordLogincheck(userName);
    let { newName, newCountry } = NewPhoneCheck(country, username);
    let newCheckCountry = country?.startsWith("+") ? country : `+${newCountry}`;
    let newUserName =
      userName.match(/@/g) !== null
        ? username
        : `${newCheckCountry}-${newName}`;
    setLoading(true);
    let _isUser = await isUserFn({
      userName: newUserName,
    });
    if (_isUser) {
      props.dispatch({
        path: "setPass",
        userName: newName,
        countryCode: newCheckCountry,
      });
    } else {
      setLoading(false);
      message.error("此手机号/邮箱未绑定账号");
    }
  };

  return (
    <>
      <div className="admin_form">
        <h2 className="admin_from_title">{title}</h2>
        <Icon
          type="close"
          className="admin_right"
          onClick={() => {
            props.onClose("");
          }}
        />
        <div className="login_form">
          <div>
            <UserInputComponent
              onChange={dispatch}
              flag={false}
              placeholder="请输入手机号码或邮箱"
            />
          </div>
          <div style={{ margin: "20px 0" }}>
            {" "}
            <SliderComponent state={data.state} onChange={dispatch} />{" "}
          </div>
          <Button
            onClick={onSubmit}
            loading={loading}
            style={{
              width: "100%",
              background: data.state ? "rgba(119,158,185,1)" : "#ccc",
              color: "#fff",
            }}
          >
            下一步
          </Button>
          <span
            className="no_password font_color_link"
            onClick={() => {
              props.dispatch({ path: "login" });
            }}
          >
            又想起来了
          </span>
        </div>
      </div>
    </>
  );
};

const SetpasswordComponent = (props) => {
  let { flag, data } = props; //flag 找回密码/注册
  const [pass, setPass] = useState({
    password: "",
    PasswordAgain: "",
    captcha: "",
  });

  const dispatch = (json) => setPass({ ...pass, ...json });

  let { password, PasswordAgain } = pass;
  let pathname = new URLSearchParams(props.location?.search);
  let channelCode = pathname.get("channel");
  const [passStatus, setPassstatus] = useState({
    test_length: false,
    test_identical: false,
    test_blend: false,
  });

  const TestValue = useCallback(() => {
    let json = { test_length: false, test_identical: false, test_blend: false };
    if (password) {
      password.length >= 6 && password.length <= 16
        ? (json.test_length = true)
        : (json.test_length = false); //大于6且小于16
      password.match(/(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]/) !== null
        ? (json.test_blend = true)
        : (json.test_blend = false); //密码字母与数字混合 不可出现纯数字 与纯字母
      password === PasswordAgain
        ? (json.test_identical = true)
        : (json.test_identical = false); //相同
    }
    setPassstatus((passStatus) => {
      return { ...passStatus, ...json };
    });
  }, [password, PasswordAgain]);

  useMemo(() => {
    TestValue();
  }, [TestValue]);

  const { test_length, test_blend, test_identical } = passStatus;

  let { countryCode, userName = "" } = data;
  const onSubmit = async () => {
    if (!pass.captcha) {
      message.error("请输入验证码");
      return;
    }
    if (test_length && test_blend && test_identical) {
      if (!flag) {
        //找回密码
        const res = await resetPwd({
          userName: userName,
          userPwd: pass.password,
          captcha: pass.captcha,
        });
        if (res.code !== 0) {
          message.error(res.message);
        } else {
          Modal.success({
            content: <span style={{ color: "#fff" }}>重置密码成功</span>,
            okText: "去登录",
            onOk: () => {
              props.dispatch({ path: "login" });
            },
          });
        }
      } else {
        const fetchJson =
          userName.match(/@/g) === null
            ? {
                userNameFormat: 1,
                userName: countryCode + "-" + userName,
                captcha: pass.captcha,
                userPwd: pass.password,
                promotionChannel: channelCode,
              }
            : {
                userNameFormat: 2,
                userName,
                captcha: pass.captcha,
                userPwd: pass.password,
                promotionChannel: channelCode,
              };
        const res = await Join(fetchJson);
        if (res.code !== 0) {
          message.error(res.message);
        } else {
          message.success("注册成功");
          sessionStorage.removeItem("infoData");
          if (props.isCourse) {
            window.location.href = "";
          } else {
            props.history.push("/home/mycourses");
          }
        }
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    props.dispatch({ captcha: "" });
  }, [flag]);
  return (
    <div className="admin_form">
      <h2 className="admin_from_title">设置密码</h2>

      <div className="login_form">
        <Icon
          type="close"
          className="admin_right"
          onClick={() => {
            props.onClose("");
          }}
        />
        {countryCode === "+86" || userName.match(/@/g) !== null ? (
          <div style={{ margin: "10px 0", overflow: "hidden" }}>
            <Input
              style={{ width: "60%", float: "left" }}
              name="captrue"
              placeholder="请输入短信验证码"
              onChange={({ target: { value } }) => dispatch({ captcha: value })}
            />
            <CaptchaComponentRegist
              action={!flag ? 2 : 0}
              userName={data.userName}
              dispatch={props.dispatch}
              captchaValue={data.captchaValue}
              captchaId={data.captchaId}
              countryCode={data.countryCode}
              isNeedGraph={
                userName.match(/@/g) !== null
                  ? false
                  : data.countryCode.startsWith("+86")
                  ? false
                  : true
              }
            />
          </div>
        ) : (
          <MergeCaptachAndGraphCapcha
            userName={userName}
            countryCode={countryCode}
            dispatch={dispatch}
            action={!flag ? 2 : 0}
          />
        )}

        {data.isSend ? (
          <div style={{ margin: "10px 0" }}>
            <span style={{ color: "#fff", fontSize: "12px" }}>
              验证码已发送至{userName.match(/@/g) ? "邮箱" : "手机"}{" "}
              <span style={{ color: "yellow" }}>{userName} </span>,请查收
            </span>
          </div>
        ) : (
          ""
        )}

        <div style={{ margin: "10px 0" }}>
          <PassWordComponent
            autoComplete="new-password"
            onChange={(value) => {
              dispatch({ password: value });
            }}
            placeholder="请输入密码"
          />
        </div>

        <div style={{ margin: "10px 0" }}>
          <PassWordComponent
            autoComplete="new-password"
            onChange={(value) => dispatch({ PasswordAgain: value })}
            placeholder="再次输入密码"
          />
        </div>
        <div className="test_password_div">
          <div className={test_length ? "test_password_div_active" : ""}>
            <Icon type="check-circle" style={{ fontSize: "16px" }} />
            <span>长度必须在6-16个字符之间</span>
          </div>
          <div className={test_blend ? "test_password_div_active" : ""}>
            <Icon type="check-circle" style={{ fontSize: "16px" }} />
            <span>必须包含字母、数字、符号中至少两种</span>
          </div>
          <div className={test_identical ? "test_password_div_active" : ""}>
            <Icon type="check-circle" style={{ fontSize: "16px" }} />
            <span>两次密码一致</span>
          </div>
        </div>
        <Button
          onClick={onSubmit}
          style={{
            width: "100%",
            background: "rgba(119,158,185,1)",
            color: "#fff",
          }}
        >
          {flag ? "注册" : "重置密码"}
        </Button>
        {flag && (
          <span className="agree_agreement">
            注册即表示同意{" "}
            <a
              rel="noopener noreferrer"
              style={{ color: "#779EB9", fontSize: "14px" }}
              target="_blank"
              href={`${enrollFrontUrl}/static/agreement.html`}
            >
              用户协议
            </a>
            与
            <a
              rel="noopener noreferrer"
              style={{ color: "#779EB9", fontSize: "14px" }}
              target="_blank"
              href={`${enrollFrontUrl}/static/privacy.html`}
            >
              隐私政策
            </a>
          </span>
        )}
      </div>
    </div>
  );
};

const checkFn = (value) => {
  let { userName } = value;

  if (userName.match(/@/g) !== null) {
    return emailCheck(userName);
  }
  return true;
};

// function userCheck(user) {
//   return user.match(/\D/g) !== null ? false : true;
// }
