import Axios from "axios";
export const getAddress = async () => {
  let res = await Axios.get("/delivery_addresses");

  return res.data;
};

export const getPersonInfo = async () => {
  let res = await Axios.get("/profile");
  return res.data;
};

export const BindExternalChannel = async (data) => {
  let res = await Axios.post("/bind_to_third_party_account", data);
  return res.data;
};

export const GetCaptchaPhone = async (data) => {
  let res = await Axios.post("/captcha/sms", data);
  return res.data;
};

export const GetCaptchaEmail = async (data) => {
  let res = await Axios.post("/captcha/email", data);
  return res.data;
};
