import React, { useState, useEffect, useRef, useCallback } from "react";

import { Icon, message, Modal, Popover } from "antd";

import Axios from "axios";

import Login from "../../admin/login";

import {
  price_cig,
  small_enrollStatus,
  enrollStatusText,
  parseTime,
} from "./../../../global_cig";

import Loading from "./../../../loading";

import { ShowMask, HiddenMask } from "./../../../component/pass_input";

import { getPersonInfo } from "./../profile/getData";

import { getJWT } from "./../../../m-router/course/courseApi";

import { getCourseDetail } from "../class/searchApi";

import "./course.less";

// 在展示页面引入css样板文件
import "braft-editor/dist/output.css";
import { GoCCFn } from "router/index/bindccCmp";
import { JumpToAuthentic } from "./jumpFn";
import { setUserInfo } from "../App";
import { dispatch } from "../Store";
// 给用于展示HTML内容的容器加上特定的className
const { formLocation } = require("../../../devloader");
let sourceMap = {
  weibo: "微博",
  bzhan: "b站",
  wechat: "wechat",
  CGer: "CGer",
  element3ds: "element3ds",
  cgzixue: "cgzixue",
  cgylw: "cgylw",
  douyin: "抖音",
  gdt: "gdt",
  pyq: "朋友圈",
};
const QQGroupSvg = () => (
  <svg
    t="1590395754592"
    fill="currentColor"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3912"
    width="1.5em"
    height="1.5em"
  >
    <path
      d="M342.79487 892.63c-12.29-1.17-34.84-9-47.81-48.38-16.14-48.86-10.68-116.6 15.39-190.71 10.67-30.32 27.16-61.16 49.13-91.93-3.29-11.17-4.95-22.48-4.95-33.82 0-24.92 7.84-61.08 43.37-97 9.77-75.29 41.84-144.46 91.19-196.11 17.37-18.18 36.43-33.67 56.72-46.32-46.34-53.12-110.18-85.96-180.68-85.96-132.6 0-241.6 116.04-254.97 264.86-26.26 23.02-41.58 49.82-41.58 78.54 0 13.76 3.76 27.04 10.33 39.74-17.79 22.7-42.17 57.82-56.74 99.22-23.57 67-27.94 126.29-15.14 165.03 11.51 34.95 31.69 31.83 48.29-0.47 4.27-8.3 10.72-19.81 17.88-32.35 12.27 41.93 32.8 80.29 59.83 112.81-16.58 5.56-30.93 12.15-39.08 19.73-30.76 28.89-32.11 62.43-6.03 88.12 33.72 33.27 170.27 49.54 249.61 0.64 8.97 0.84 17.96 1.38 27.14 1.4 5.45-11.52 13.89-23.73 26.72-35.78l0.12-0.11c2.53-2.34 5.19-4.5 7.97-6.47-4.94-7.96-9.56-16.15-13.87-24.54-10.44 11.82-24.72 21.57-42.84 19.86z m660.39-229.16h0.02c-13.65-38.84-36.55-71.76-53.23-93.08 6.19-11.91 9.69-24.38 9.69-37.28 0-26.95-14.39-52.08-39-73.68-12.53-139.57-114.82-248.46-239.16-248.46-124.39 0-226.65 108.86-239.18 248.46-24.63 21.6-39 46.73-39 73.68 0 12.91 3.52 25.37 9.69 37.28-16.68 21.29-39.56 54.24-53.23 93.08-22.11 62.85-26.21 118.47-14.2 154.82 10.8 32.79 29.73 29.86 45.3-0.44 4.01-7.79 10.06-18.59 16.78-30.35 11.52 39.33 30.77 75.32 56.13 105.82-15.55 5.22-29.01 11.4-36.66 18.51-28.85 27.11-30.12 58.57-5.66 82.66 31.63 31.21 159.73 46.47 234.16 0.6 8.55 0.8 17.13 1.32 25.88 1.32 9.34 0 18.56-0.52 27.67-1.4 74.38 45.98 202.69 30.69 234.35-0.52 24.44-24.09 23.15-55.56-5.68-82.66-7.9-7.37-21.97-13.68-38.21-19.05 25.16-30.4 44.23-66.15 55.68-105.28 6.72 11.76 12.77 22.56 16.78 30.35 15.57 30.28 34.48 33.21 45.3 0.44 11.99-36.35 7.89-91.96-14.22-154.82z"
      p-id="3913"
    ></path>
  </svg>
);

const LineSvg = () => (
  <svg
    t="1591086600484"
    fill="currentColor"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3285"
    width="1.5em"
    height="1.5em"
  >
    <path
      d="M826.24 420.48c14.848 0 26.88 12.16 26.88 26.88 0 14.72-12.032 26.88-26.88 26.88h-74.88v48h74.88a26.88 26.88 0 1 1 0 53.76H724.48a26.88 26.88 0 0 1-26.752-26.88V345.6c0-14.72 12.032-26.88 26.88-26.88h101.76a26.88 26.88 0 0 1-0.128 53.76h-74.88v48h74.88zM661.76 549.12a26.944 26.944 0 0 1-26.944 26.752c-8.96 0-16.64-3.84-21.76-10.688L508.8 423.68v125.44A26.88 26.88 0 0 1 481.92 576a26.88 26.88 0 0 1-26.688-26.88V345.6a26.752 26.752 0 0 1 26.624-26.816c8.32 0 16 4.48 21.12 10.88L608 471.68V345.6c0-14.72 12.032-26.88 26.88-26.88 14.72 0 26.88 12.16 26.88 26.88v203.52z m-244.992 0c0 14.72-12.032 26.88-26.88 26.88a26.88 26.88 0 0 1-26.752-26.88V345.6c0-14.72 12.032-26.88 26.88-26.88 14.72 0 26.752 12.16 26.752 26.88v203.52zM311.552 576h-101.76c-14.72 0-26.88-12.16-26.88-26.88V345.6c0-14.72 12.16-26.88 26.88-26.88 14.848 0 26.88 12.16 26.88 26.88v176.64h74.88a26.88 26.88 0 1 1 0 53.76M1024 439.68c0-229.12-229.76-415.616-512-415.616S0 210.56 0 439.68C0 644.992 182.144 817.024 428.16 849.6c16.64 3.584 39.36 11.008 45.12 25.216 5.12 12.8 3.392 32.64 1.664 46.08l-7.04 43.52c-1.92 12.8-10.24 50.56 44.8 27.52 55.04-23.04 295.04-174.08 402.56-297.6C988.8 613.76 1024 531.2 1024 439.68"
      fill=""
      p-id="3286"
    ></path>
  </svg>
);

const MailSvg = () => (
  <svg
    t="1591086532387"
    fill="currentColor"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="1925"
    width="1.5em"
    height="1.5em"
  >
    <path
      d="M512 580.26666667a34.13333333 34.13333333 0 0 1-18.3296-5.3248L102.4 325.9392v459.3664A68.26666667 68.26666667 0 0 0 170.73493333 853.33333333h682.53013334A68.26666667 68.26666667 0 0 0 921.6 785.3056V326.28053333l-391.2704 248.66133334A34.23573333 34.23573333 0 0 1 512 580.26666667"
      p-id="1926"
    ></path>
    <path
      d="M853.26506667 170.66666667H170.73493333A68.26666667 68.26666667 0 0 0 102.4 238.72853333v5.2224l409.6 261.7344 409.6-260.3008v-6.656A68.26666667 68.26666667 0 0 0 853.26506667 170.66666667"
      p-id="1927"
    ></path>
  </svg>
);

const weChartSvg = () => (
  <svg
    t="1618994028224"
    fill="#ffffff"
    viewBox="0 0 1228 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="11410"
    width="1.5em"
    height="1.5em"
  >
    <path d="M831.83 311.433c14.13 0 28.057 0.955 41.915 2.457C836.13 143.565 648.738 17.067 434.927 17.067 195.857 17.067 0 175.445 0 376.559 0 492.612 65.195 587.98 174.08 661.914l-43.486 127.112 152.03-74.07c54.409 10.446 98.03 21.163 152.303 21.163 13.653 0 27.17-0.682 40.619-1.638a305.766 305.766 0 0 1-13.449-88.542c0-184.661 163.157-334.506 369.732-334.506zM598.015 196.745c32.768 0 54.409 20.957 54.409 52.838 0 31.676-21.71 52.907-54.409 52.907-32.563 0-65.331-21.3-65.331-52.907 0-31.88 32.768-52.77 65.331-52.77zM293.615 302.49c-32.563 0-65.536-21.3-65.536-52.907 0-31.812 32.904-52.77 65.536-52.77s54.34 20.89 54.34 52.77c0 31.607-21.709 52.907-54.34 52.907zM1228.8 640.82c0-168.96-174.012-306.723-369.46-306.723-206.847 0-369.868 137.762-369.868 306.722 0 169.302 163.02 306.79 369.869 306.79 43.35 0 87.04-10.649 130.526-21.162l119.262 63.42-32.7-105.609C1163.81 820.565 1228.8 736.12 1228.8 640.82z m-489.335-52.907c-21.641 0-43.486-20.89-43.486-42.326 0-21.026 21.845-42.189 43.486-42.189 32.904 0 54.476 21.163 54.476 42.257 0 21.368-21.572 42.326-54.476 42.326z m239.206 0c-21.504 0-43.213-20.89-43.213-42.326 0-21.026 21.709-42.189 43.213-42.189 32.631 0 54.477 21.163 54.477 42.257 0 21.368-21.846 42.326-54.477 42.326z"></path>
  </svg>
);

const App = (props) => {
  const [state, setState] = useState({
    path: "",
    qqModal: false,
    course_data: {},
    loading: true,
    enroll_condition: [],
  });
  const [iframeVisable, setIframeVisible] = useState({
    formTips: false,
    iframeShow: false,
  });

  const reload_fn = async () => {
    await Axios.get(`/course_codes/${props.match.params.id}`).then((res) => {
      if (res.data.code !== 0) {
        message.error(res.data.message);
        props.history.goBack();
      } else {
        console.log(res.data.data);
        let course_data = res.data.data;

        //报名前需要完成认证
        let {
          formId,
          qqGroup = {},
          payStage,
          formCompleted,
          qqNumber,
          popup,
          teacherWechat = {},
          wechatId,
          popupQQGroup,
          isSubOrder,
          needBindCCTalk,
          needBindPhoneNumber,
          needCompleteAuthentication,
          needFillOutTheForm,
          loggedOn,
        } = course_data;

        if (loggedOn) {
          setUserInfo();
        } else {
          localStorage.removeItem("jwt");
          sessionStorage.removeItem("userData");
          dispatch({ type: "SETDATA", payload: {} });
        }
        //                is it Group Object empty ?
        let qqGroupState = qqGroup && qqGroup.name && !qqNumber ? 1 : 0;

        let weChatState =
          teacherWechat && teacherWechat.name && wechatId === "" ? 6 : 0;

        //  是否需要去认证        complete payment or (need enroll condition and is it certifificated)
        let PreState = needCompleteAuthentication ? 3 : 0;
        //                 it is paymented order and that need complete next step
        let depositState = !isSubOrder && payStage === 2 ? 4 : 0;

        //判断是否需要填写表单
        let showFormTable = needFillOutTheForm ? 5 : 0;
        let isNeedCCtalk = needBindCCTalk ? 7 : 0;
        let isNeedBindPhone = needBindPhoneNumber ? 8 : 0;
        let enroll_condition = [
          showFormTable,
          PreState, //是否需要认证
          depositState, //支付
          qqGroupState,
          weChatState,
          isNeedCCtalk,
          isNeedBindPhone,
        ].filter((i) => i !== 0);
        let qqModal = false;
        popup === 1 && showFormTable === 5 && show_form_title();
        if (
          (formId !== 0 && popup === 1 && formCompleted && popupQQGroup) ||
          (formId === 0 && popupQQGroup) ||
          (formId !== 0 && popup !== 1 && popupQQGroup)
        ) {
          //QQ推送的判断
          qqModal = true;
        }
        // setQQmodal(true);
        setState((state) => ({
          ...state,
          course_data,
          loading: false,
          enroll_condition,
          qqModal,
        }));
      }
    });
  };
  useEffect(() => {
    let pathname = new URLSearchParams(props.location?.search);
    let channelCode = pathname.get("channel");
    if (channelCode) {
      sessionStorage.setItem("channel", channelCode);
    }
  }, []);
  useEffect(() => {
    setTimeout(() => {
      reload_fn();
    }, 500);
    sessionStorage.setItem("courseCode", props.match.params.id);
  }, []);
  let { enroll_condition, course_data, loading, formTips, qqModal } = state;
  let {
    picture,
    enrollStatus,
    seriesName,
    deposit,
    name,
    price,
    currency,
    availableCoupon,
    details,
    qqGroup = {},
    contactList,
    qqNumber,
    precondition,
    orderNumber,
    id,
    popup,
    formId,
    formDesc,
    formButton,
    payStage,
    balance,
    wechatId,
    teacherWechat = {},
    preCourseList,
    preCourseNum,
    currentStepNumber,
    popupQQGroup,
    formCompleted,
    preCourseTopTips,
    preCourseBottomTips,
    isSubOrder,
  } = course_data;

  useEffect(() => {
    getLocationJwt();
    let pathname = new URLSearchParams(props.location.search);
    const source = pathname.get("website");
    if (source !== null) {
      sessionStorage.source = sourceMap[source];
    }
  }, []);

  const getLocationJwt = () => {
    let pathname = new URLSearchParams(props.location.search);
    const jwt = pathname.get("locationJWT");
    if (!jwt) return;
    localStorage.jwt = jwt;
    window.location.href = props.location.pathname;
  };

  useEffect(() => {
    const getToken = async () => {
      let pathname = new URLSearchParams(props.location.search);
      const key = pathname.get("key");
      if (!state.course_data.loggedOn) {
        if (!key) return;
        let res = await getJWT(key);
        if (res.code !== 0) {
          window.location.href = props.location.pathname;
          message.error(res.message);
        } else {
          localStorage.jwt = res.data.token;
          window.location.href = props.location.pathname;
        }
      }
    };
    getToken();
  }, []);

  const show_form_title = () => {
    setIframeVisible((state) => ({ ...state, formTips: true }));
  };

  const go_history = (path, state) => {
    props.history.push(path, state);
  };

  const bt_status = (value) => {
    let { loggedOn, enrollStatus, payStage, homeworkSwitch } = value;
    let status = enrollStatus;
    if (!loggedOn) {
      return {
        text: "报名请先登录",
        fn: () => {
          setState((state) => ({ ...state, path: "login" }));
        },
        className: "bt_status_1",
      };
    }
    if (status === 13) {
      return {
        text: "无法报名",
        className: "bt_status_13",
      };
    }

    if (status === 10 || status === 14 || status === 15) {
      //无需绑定CCtalk，需要完成实名认证
      return {
        fn: () => {
          JumpToAuthentic(props, status);
        },
        text: small_enrollStatus[status],
        className: "bt_status_1",
      };
    }
    if (status === 12) {
      return {
        text: small_enrollStatus[status],
        className: "bt_status_null",
      };
    }
    if (status === 11) {
      return {
        fn: () => {},
        text: small_enrollStatus[status],
        className: "bt_status_null",
      };
    }
    if (
      status === 1 ||
      status === 2 ||
      status === 3 ||
      status === 0 ||
      status === 9
    ) {
      return {
        text: small_enrollStatus[status],
        fn: () => false,
        className: "bt_status_null",
      };
    }
    if (status === 6) {
      return {
        text:
          payStage === 1
            ? "支付定金"
            : payStage === 2
            ? "支付尾款"
            : small_enrollStatus[status],
        fn: () => {
          go_history(`/home/course_order/${state.course_data.orderNumber}`);
        },
        className: "bt_status_1",
      };
    }
    if (status === 7) {
      return {
        text: small_enrollStatus[status],
        fn: () => {},
        className: "bt_status_null",
      };
    }
    if (status === 8) {
      return !qqGroup || homeworkSwitch === 0
        ? {
            text: small_enrollStatus[status],
            className: "bt_status_null",
          }
        : {
            text: (
              <span>
                <span>{small_enrollStatus[status]}</span>
                <br />
                <span style={{ fontSize: "12px" }}>查看课程作业{">>"}</span>
              </span>
            ),
            fn: () =>
              props.history.push(`/home/coursework/${state.course_data.code}`),
            className: "bt_status_success",
          };
    }
    return {
      text: small_enrollStatus[status],
      fn: () => {
        go_history("/home/enrolments", value);
      }, //前往订单页面
      className: "bt_status_1",
    };
  };

  const editQQnumber = async (body) => {
    Axios.put(`/enrolments/${state.course_data.orderNumber}`, body).then(
      (res) => {
        if (res.data.code !== 0) {
          message.error(res.data.message);
        } else {
          reload_fn();
        }
      }
    );
  };

  const setPath = (path) => {
    setState((state) => ({ ...state, path }));
  };

  const ApplicationForm = () => {
    setIframeVisible((state) => ({ ...state, iframeShow: true }));
  };

  let form_config = { formId, formDesc, formButton };
  let _details = details ? details : []; //标签
  let FocdeStatus = false;
  if (enrollStatus === 5) FocdeStatus = true; //F码Icon显示 availableCoupon优惠券Icon显示
  contactList = contactList ? contactList : [];
  const bt = bt_status(course_data);
  return loading ? (
    <Loading />
  ) : (
    <div className="Course_detail">
      {qqModal && (
        <QQModalCmp
          qqGroup={qqGroup}
          setState={setState}
          qqModal={qqModal}
          editQQnumber={editQQnumber}
        />
      )}
      {iframeVisable.formTips && !iframeVisable.iframeShow ? (
        <ShowFormTips setIframeVisible={setIframeVisible} />
      ) : (
        ""
      )}
      {iframeVisable.iframeShow && (
        <ShowModal
          courseCode={props.match.params.id}
          formId={form_config.formId}
          reload_fn={reload_fn}
          courseId={id}
          popup={popup}
          orderNumber={orderNumber}
          close={() => {
            setIframeVisible((state) => ({
              ...state,
              iframeShow: false,
              formTips: false,
            }));
          }}
        />
      )}
      <Login path={state.path} isCourse={true} setPath={setPath} {...props} />
      <div className="course_father">
        <div className="article-hero">
          <div className="Course_left">
            <div
              className="course_left_img"
              style={{ backgroundImage: `url(${picture})` }}
            ></div>
          </div>
        </div>
        <div className="Course_div">
          <h3 className="course_title">
            {seriesName} - {name}
          </h3>
          {/* 列表 */}
          <CourseInforMation
            value={course_data}
            setPath={setPath}
            reload_fn={reload_fn}
          />
          <div className="course_bt_status">
            <div>
              {enrollStatus === 13 ? (
                <Popover
                  placement="right"
                  trigger="click"
                  content={
                    <div style={{ width: "600px", minHeight: "50px" }}>
                      <div>{preCourseTopTips}</div>
                      <div className="">
                        {preCourseList.map((i, index) => (
                          <div key={index}>{i}</div>
                        ))}
                        {preCourseNum > preCourseList.length &&
                        preCourseList.length !== 0 ? (
                          <div>...</div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>{preCourseBottomTips}</div>
                    </div>
                  }
                >
                  <button className={`course_bt ${bt.className}`}>
                    {enrollStatus === 13 && (
                      <img
                        className="qualifytips"
                        src={require("../../../public/tipsicon.png")}
                      />
                    )}
                    {bt.text}
                  </button>
                </Popover>
              ) : (
                <button className={`course_bt ${bt.className}`} onClick={bt.fn}>
                  {bt.text}
                </button>
              )}
            </div>
            {enrollStatus === 7 || enrollStatus === 12 || enrollStatus === 8 ? (
              <div></div>
            ) : (
              <div className="price_div">
                <div
                  className={
                    availableCoupon || FocdeStatus || currentStepNumber !== 0
                      ? "price_div_text"
                      : "price_div_text price_div_text_active"
                  }
                >
                  {
                    enrollStatus === 6 ? (
                      <div>
                        <span>
                          {payStage === 1
                            ? deposit
                            : payStage === 2
                            ? balance
                            : price}
                        </span>
                        <b>{price_cig[currency]}</b>
                      </div>
                    ) : (
                      <div>
                        <span>{price}</span>
                        <b>{price_cig[currency]}</b>
                      </div>
                    )

                    //    deposit !== 0 ? <div>
                    //         <span>{(enrollStatus === 4 || enrollStatus === 3) ? payStage === 1 ? deposit : balance : price}</span>
                    //         <b>{price_cig[currency]}</b>
                    //         {/* {deposit !== 0 && <span className='ant-table-column-title deposit'> (定金：{deposit}{price_cig[currency]})</span>} */}
                    //     </div> : <div><span>{price}<b>{price_cig[currency]}</b></span>
                    //         </div>
                  }
                </div>
                <div className="price_div_coupon">
                  {FocdeStatus && (
                    <span className="fcodeIcon">可使用F码报名</span>
                  )}
                  {availableCoupon && (
                    <span
                      className="fcodeIcon"
                      style={{ backgroundColor: "rgb(197, 76, 28)" }}
                    >
                      优惠券
                    </span>
                  )}
                  {currentStepNumber !== 0 && (
                    <span
                      className="fcodeIcon"
                      style={{ backgroundColor: "#4CD3EE" }}
                    >
                      早鸟价
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="course_down">
        <TabComponent _details={_details} details={details} />
        <div className="course_right">
          <CourseRightComponent
            {...course_data}
            JumpToAuthentic={JumpToAuthentic}
            {...props}
            enrollStatus={enrollStatus}
            formTips={formTips}
            form_config={form_config}
            popup={popup}
            popupQQGroup={popupQQGroup}
            ApplicationForm={ApplicationForm}
            reload_fn={reload_fn}
            enroll_condition={enroll_condition}
            orderNumber={orderNumber}
            precondition={precondition}
            go_history={(url) => {
              go_history(url);
            }}
            wechatId={wechatId}
            teacherWechat={teacherWechat}
            qqNumber={qqNumber}
            qqGroup={qqGroup}
            editQQnumber={editQQnumber}
            formCompleted={formCompleted}
            isSubOrder={isSubOrder}
          />
          {
            //咨询列表
            contactList.length ? (
              <div className="center_admin">
                {contactList.map((i) => {
                  let texts;
                  let account;
                  if (i.account.match("#") !== null) {
                    [texts, account] = i.account.split("#");
                    texts = texts.replace(/\s/g, "");
                    account = account.replace(/\s/g, "");
                  } else {
                    texts = i.account.replace(/\s/g, "");
                    account = "";
                  }
                  let text = "";
                  if (i.type === 1)
                    text = (
                      <div key={i.ordinal}>
                        <div>
                          <Icon style={{ fontSize: "1.5em" }} type="qq" />
                          <div>{texts}</div>
                        </div>
                        <div>{account}</div>
                      </div>
                    );
                  if (i.type === 2)
                    text = (
                      <div key={i.ordinal}>
                        <div>
                          <Icon component={QQGroupSvg} />
                          <div>{texts}</div>
                        </div>
                        <div>{account}</div>
                      </div>
                    );
                  if (i.type === 3)
                    text = (
                      <div key={i.ordinal}>
                        <div>
                          <Icon component={LineSvg} />
                          <div>{texts}</div>{" "}
                        </div>
                        <div>{account}</div>
                      </div>
                    );
                  if (i.type === 4)
                    text = (
                      <div key={i.ordinal}>
                        <div>
                          <Icon component={MailSvg} /> <div>{texts}</div>{" "}
                        </div>
                        <div>{account}</div>
                      </div>
                    );
                  if (i.type === 6)
                    text = (
                      <div key={i.ordinal}>
                        <div>
                          <Icon component={weChartSvg} /> <div>{texts}</div>
                        </div>
                        <div>{account}</div>
                      </div>
                    );
                  if (i.type === 5)
                    text = (
                      <div key={i.ordinal}>
                        <div style={{ display: "flex" }}>
                          <img
                            style={{ marginRight: "7px" }}
                            width={"21px"}
                            height="21px"
                            src={require("../../../public/weixincode.png")}
                          />
                          <div>{texts}</div>
                        </div>
                        <div>{account}</div>
                      </div>
                    );
                  return text;
                })}
              </div>
            ) : (
              ""
            )
          }
        </div>
      </div>
      <div className="clear"></div>
    </div>
  );
};

export default App;

const ShowFormTips = (props) => {
  const { setIframeVisible } = props;
  useEffect(() => {
    ShowMask({ body: "hidden" });
    return () => {
      HiddenMask({ body: "" });
    };
  }, []);
  return (
    <div className="course_form_tips">
      <div>同学，这里有一份表单需要您填写哦！</div>
      <div>
        <button
          className="bt_blue"
          onClick={() => {
            setIframeVisible((state) => ({ ...state, iframeShow: true }));
          }}
        >
          确认
        </button>
      </div>
    </div>
  );
};

const QQModalCmp = (props) => {
  let { qqGroup, editQQnumber, setState } = props;
  let [qqNumber, setNumber] = useState("");
  const [hasQQ, setError] = useState(false);
  useEffect(() => {
    ShowMask({ body: "hidden" });
    return () => {
      HiddenMask({ body: "" });
    };
  }, []);

  const submitQQ = () => {
    if (qqNumber.length === 0) {
      setError(true);
      return;
    }
    if (qqNumber.length > 13 || /[^0-9]/g.test(qqNumber)) {
      message.destroy();
      message.error("格式错误，请检查后提交");
    } else {
      Modal.confirm({
        getContainer: false,
        mask: false,
        content: (
          <span style={{ color: "#fff" }}>
            请确认进群的QQ号为:
            {<span style={{ color: "yellow" }}>{qqNumber}</span>}
          </span>
        ),
        onOk: () => {
          //setState((state) => ({ ...state, qqModal: false }))
          editQQnumber({ qqNumber: qqNumber * 1 });
        },
      });
    }
  };
  return (
    <div className="form_iframe2">
      <div
        onClick={() => {
          setState((state) => ({ ...state, qqModal: false }));
        }}
        style={{
          color: "#fff",
          position: "absolute",
          top: "10px",
          right: "10px",
          fontSize: "18px",
          cursor: "pointer",
        }}
      >
        X
      </div>
      <div className="qqmodalBox">
        <div>
          <div style={{ textAlign: "left" }}>
            <span className="radius">1</span>
            <span>请加入班级QQ群</span>
          </div>
          <div style={{ marginLeft: !props.qqNumber && "27px" }}>
            <div
              className="flex_start"
              style={{ width: "100%", alignItems: "initial" }}
            >
              <div>QQ群名称：</div>
              <div style={{ wordWrap: "break-word", wordBreak: "break-all" }}>
                {qqGroup?.name}
              </div>
            </div>
            <div className="flex_start">
              <div>QQ群号：</div>
              <div>{qqGroup?.groupNumber}</div>
            </div>
          </div>
          <div style={{ width: "200px", height: "200px" }}>
            {qqGroup?.QRCodeUrl && (
              <img
                src={qqGroup?.QRCodeUrl}
                alt="qrcode"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  padding: "0 27px",
                }}
              />
            )}
          </div>
        </div>
        <div style={{ textAlign: "left", marginTop: "20px" }} className="left">
          <span className="radius">2</span>
          <span>
            您加群的<span style={{ color: "yellow" }}>个人QQ号</span>
          </span>
          <div className="center">
            <input
              style={{ marginRight: "10px", padding: "0 5px", width: "145px" }}
              maxLength={13}
              onChange={(e) => {
                setError(false);
                setNumber(e.target.value);
              }}
              className="qqGroup_ipt"
            />
            <button
              onClick={() => submitQQ()}
              className={`bt_blue `}
              style={{ width: "48px", lineHeight: "32px" }}
            >
              确认
            </button>
          </div>
          {hasQQ ? <div style={{ color: "red" }}>请输入QQ号</div> : ""}
        </div>
      </div>
    </div>
  );
};
const CourseRightComponent = (props) => {
  const [remindMessage, setMessage] = useState(0);
  let {
    enroll_condition,
    cctalkGroups,
    enrollStatus,
    formTips,
    form_config,
    precondition,
    go_history,
    qqNumber,
    qqGroup,
    editQQnumber,
    orderNumber,
    ApplicationForm,
    teacherWechat,
    wechatId,
    popupQQGroup,
    popup,
    formCompleted,
    payStage,
    JumpToAuthentic,
    // isSubOrder,
  } = props;
  const getRemind = useCallback(() => {
    if (popupQQGroup || (popup && !formCompleted)) return;
    let remindObject = localStorage.readRemind
      ? JSON.parse(localStorage.readRemind)
      : [];
    let newRemind = enroll_condition.filter(
      (i) => remindObject.indexOf(i) === -1
    )[0];
    setMessage(() => newRemind || 0);
  }, [enroll_condition, popupQQGroup]);

  useEffect(() => {
    getRemind();
  }, [getRemind]);

  const setRemind = (key) => {
    let remindObject = localStorage.readRemind
      ? JSON.parse(localStorage.readRemind)
      : [];
    remindObject.push(key);
    localStorage.readRemind = JSON.stringify(remindObject);
    getRemind();
    return;
  };

  const bindCCTalk = () => {
    GoCCFn();
  };
  return (
    <div>
      {payStage > 1 && enroll_condition.length ? (
        <div style={{ fontSize: "12px", textAlign: "center" }}>
          {orderNumber !== "" ? (
            <h2 className="current_color">完成以下事项后可报名成功</h2>
          ) : (
            ""
          )}
          {cctalkGroups.length && cctalkGroups.some((i) => i.status === 1) ? (
            <h2 className="current_color">您将会自动加入CCtalk直播上课群</h2>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {/* 根据支付状态和是否有群来判断是否需要显示cctalk模块 */}
      {payStage > 1 &&
      enrollStatus !== 12 &&
      cctalkGroups.some((i) => i.status === 1) ? (
        <CctalkComponents
          cctalkGroups={cctalkGroups}
          enrollStatus={enrollStatus}
        />
      ) : (
        ""
      )}
      {enroll_condition.map((i) => {
        let RemindObject = {
          setRemind: () => setRemind(i),
          remindKey: i,
          remindMessage,
        };
        let isReadRemind = !formTips && remindMessage === i;
        return i === 7 ? (
          <div
            key={i}
            className={`course_right_box ${isReadRemind && "remindMask"}`}
          >
            <div>
              <div className="bindingCCtalkTips">
                请绑定上课必要工具CCtalk的账号
              </div>
              <div className="bindingCCtalkBtn" onClick={bindCCTalk}>
                前往完成绑定
              </div>
            </div>
            <section className="myCourseInfoIcon"></section>
            {isReadRemind && <RemindComponent {...RemindObject} />}
          </div>
        ) : i === 5 ? (
          <div
            className={`course_right_box ${isReadRemind && "remindMask"}`}
            key={i}
          >
            <div className="course_blance">
              <div>{form_config.formDesc}</div>
              <div
                style={isReadRemind ? { cursor: "auto" } : {}}
                className="bt_blue course_right_bt"
                onClick={() => {
                  !isReadRemind && ApplicationForm();
                }}
              >
                {form_config.formButton}
              </div>
              <section className="myCourseInfoIcon"></section>
            </div>
            {isReadRemind && <RemindComponent {...RemindObject} />}
          </div>
        ) : i === 3 ? (
          <div
            className={`course_right_box ${isReadRemind && "remindMask"}`}
            key={i}
          >
            <h3> 本课程报名前需要完成厚薄网站认证</h3>
            <button
              style={isReadRemind ? { cursor: "auto" } : { width: "100%" }}
              className="bt_blue course_right_bt"
              onClick={() => {
                !isReadRemind && JumpToAuthentic(props, enrollStatus);
              }}
            >
              前往完成实名认证
            </button>
            {precondition !== 2 && (
              <section className="myCourseInfoIcon"></section>
            )}
            {isReadRemind && <RemindComponent {...RemindObject} />}
          </div>
        ) : i === 8 ? (
          <div
            className={`course_right_box ${isReadRemind && "remindMask"}`}
            key={i}
          >
            <h3> 本课程报名前需要绑定手机号</h3>
            <button
              style={isReadRemind ? { cursor: "auto" } : { width: "100%" }}
              className="bt_blue course_right_bt"
              onClick={() => {
                !isReadRemind && go_history("/home/profile/security");
              }}
            >
              前往完成绑定
            </button>
            {precondition !== 2 && (
              <section className="myCourseInfoIcon"></section>
            )}
            {isReadRemind && <RemindComponent {...RemindObject} />}
          </div>
        ) : i === 1 ? (
          qqGroup ? (
            <QQgroupComponent
              isReadRemind={isReadRemind}
              {...RemindObject}
              key={i}
              qqNumber={qqNumber}
              qqGroup={qqGroup}
              editQQnumber={editQQnumber}
            />
          ) : (
            ""
          )
        ) : i === 6 ? (
          teacherWechat ? (
            <WeChatComponent
              isReadRemind={isReadRemind}
              {...RemindObject}
              key={i}
              wechatId={wechatId}
              teacherWechat={teacherWechat}
              editQQnumber={editQQnumber}
            />
          ) : (
            ""
          )
        ) : i === 4 ? (
          <div
            className={`course_right_box ${isReadRemind && "remindMask"}`}
            key={i}
          >
            <div className="course_blance">
              <div>请支付课程尾款</div>
              <div
                className="bt_blue course_right_bt"
                style={isReadRemind ? { cursor: "auto" } : {}}
                onClick={() => {
                  !isReadRemind &&
                    go_history(`/home/course_order/${orderNumber}`);
                }}
              >
                支付尾款
              </div>
              <section className="myCourseInfoIcon"></section>
            </div>
            {isReadRemind && <RemindComponent {...RemindObject} />}
          </div>
        ) : (
          <div></div>
        );
      })}
      {enrollStatus !== 12 && qqGroup && qqNumber ? (
        <QQgroupComponent
          qqNumber={qqNumber}
          qqGroup={qqGroup}
          editQQnumber={editQQnumber}
        />
      ) : (
        ""
      )}
      {teacherWechat && wechatId !== "" ? (
        <WeChatComponent wechatId={wechatId} teacherWechat={teacherWechat} />
      ) : (
        ""
      )}
    </div>
  );
};

// {(enroll_condition.length||enrollStatus===8)&&<div>

//     </div>}
const WeChatComponent = (props) => {
  let [weixincode, setNumber] = useState("");
  let {
    teacherWechat,
    editQQnumber,
    setRemind,
    remindKey,
    remindMessage,
    isReadRemind,
  } = props;
  teacherWechat = teacherWechat || {};
  const submitQQ = () => {
    if (weixincode.length <= 0) {
      message.error("请输入您的微信号", 2);
    } else {
      Modal.confirm({
        content: (
          <span style={{ color: "#fff" }}>
            请确认您的微信号为:
            {<span style={{ color: "yellow" }}>{weixincode}</span>}
          </span>
        ),
        onOk: () => {
          editQQnumber({ wechatId: weixincode });
        },
      });
    }
  };
  return (
    <div
      style={{ textAlign: "left" }}
      className={`course_right_box ${isReadRemind && "remindMask"}`}
    >
      {props.wechatId === "" && (
        <div style={{ textAlign: "left" }}>
          <span className="radius">1</span>
          <span>请添加班主任微信</span>
        </div>
      )}
      <div style={{ marginLeft: props.wechatId === "" && "27px" }}>
        <div
          className="flex_start"
          style={{ width: "100%", alignItems: "initial" }}
        >
          <div style={{ flex: "0 0 56px" }}>班主任：</div>
          <div style={{ textAlign: "left", wordWrap: "break-word" }}>
            {teacherWechat.name}
          </div>
        </div>
        <div className="flex_start">
          <div>班主任微信号：</div>
          <div style={{ maxWidth: "162px" }}>{teacherWechat.wechatId}</div>
        </div>
      </div>
      <div>
        {teacherWechat.QRCodeUrl && (
          <img
            src={teacherWechat.QRCodeUrl}
            alt="qrcode"
            style={{
              width: "80%",
              height: "80%",
              margin: "15px auto",
              display: "block",
            }}
          />
        )}
      </div>
      {props.wechatId === "" && (
        <div style={{ textAlign: "left", marginTop: "20px" }} className="left">
          <span className="radius">2</span>
          <span>
            您加群的<span style={{ color: "yellow" }}>个人微信号</span>
          </span>
        </div>
      )}
      {props.wechatId !== "" ? (
        <div className="course_right_weixindiv" style={{ width: "100%" }}>
          <div>您加群的微信号为：</div>
          <div style={{ color: "yellow" }}>{props.wechatId}</div>
        </div>
      ) : (
        <div className="center">
          <input
            disabled={!isReadRemind ? false : true}
            style={{ marginRight: "10px", padding: "0 5px", width: "145px" }}
            maxLength={20}
            onChange={(e) => {
              if (e.target.value.length > 20) return;
              setNumber(e.target.value.replace(/[^\w_-]/g, ""));
            }}
            className="qqGroup_ipt"
            value={weixincode}
          />
          <button
            onClick={() => !isReadRemind && submitQQ()}
            className={`bt_blue ${isReadRemind && "cursor_none"}`}
            style={{ width: "48px", lineHeight: "32px" }}
          >
            确认
          </button>
        </div>
      )}
      {props.wechatId === "" && (
        <section className="course_info myCourseInfoIcon"></section>
      )}
      {props.wechatId === "" && isReadRemind && (
        <RemindComponent
          setRemind={setRemind}
          remindKey={remindKey}
          remindMessage={remindMessage}
        />
      )}
    </div>
  );
};
const CctalkComponents = ({ cctalkGroups, enrollStatus }) => {
  return cctalkGroups.length > 0 ? (
    <div>
      {cctalkGroups
        .filter((i) => i.status === 1)
        .map((i) => (
          <div key={i.id} className="course_cctalk_group">
            <div>{i.name}</div>
            {enrollStatus === 8 ? (
              <div className="course_green_text">已加入</div>
            ) : (
              <div className="course_yellow_text">未加入</div>
            )}
          </div>
        ))}
      {cctalkGroups.some((i) => i.status === 1) ? (
        <div className="course_cctalk_group">
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.cctalk.com/download"
            >
              点击下载CCtalk客户端
            </a>
          </div>
          <div>
            <a href="/home/help/about">遇到问题</a>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  );
};

const RemindComponent = (props) => {
  let RemindText = [];
  RemindText[8] = "为保障后续上课顺利进行，请绑定手机号~";
  RemindText[7] = "CCtalk为本课程的上课工具，请您务必完成绑定~";
  RemindText[5] = "填写表单，并完成所有后续事项，才算报名成功哦~";
  RemindText[4] = "本课程为定金+尾款的收费方式，需缴清尾款才能上课哦~";
  RemindText[3] = "为保护付费学员的利益，请您务必完成实名认证";
  RemindText[1] = (
    <span>
      请在这里填写您的<span style={{ color: "yellow" }}>个人QQ号</span>
      ，并确保使用此QQ号进群
    </span>
  );
  RemindText[6] = (
    <span>
      请在这里填写您的<span style={{ color: "yellow" }}>个人微信号</span>
      ，并确保使用此微信号进群
    </span>
  );

  let { setRemind, remindMessage } = props;

  useEffect(() => {
    ShowMask();
    let dom = document.querySelector(".remind");
    dom && dom.scrollIntoView({ behavior: "smooth", block: "center" });
    return () => {
      HiddenMask({ body: "" });
    };
  }, []);

  return (
    <div className="remind">
      <div>
        <div
          className="remind_btn"
          onClick={() => {
            setRemind();
          }}
        >
          我知道了
        </div>
      </div>
      <div>{RemindText[remindMessage]}</div>
    </div>
  );
};

const CourseInforMation = (props) => {
  let {
    value: {
      name,
      seriesId,
      enrollStatus,
      seriesName,
      quota,
      courseStatus,
      remainingEnrolmentNumber,
      collected,
      code,
      loggedOn,
      currentStepNumber,
    },
  } = props;

  const [course_series, setSeries] = useState([]);

  const [visible, setVisible] = useState(false);

  const [selectShow, setSelectShow] = useState(false);

  const joinMycollect = () => {
    Axios.put(`/favorite_courses/1/${code}`).then((res) => {
      if (res.data.code !== 0) {
        message.destroy();
        message.error(res.data.message);
      } else {
        message.success("收藏成功");
        props.reload_fn();
      }
    });
  };

  const deleteMycollect = () => {
    Axios.delete(`/favorite_courses/1/${code}`).then((res) => {
      if (res.data.code !== 0) {
        message.destroy();
        message.error(res.data.message);
      } else {
        message.success("已取消收藏");
        props.reload_fn();
      }
    });
  };

  const get_course_list = () => {
    if (course_series.length || !seriesId) return;
    Axios.get(`/course_series/${seriesId}`).then((res) => {
      if (res.data.code !== 0) {
        message.destroy();
        message.error(res.data.message);
      } else {
        setSeries(res.data.data);
        setVisible(true);
      }
    });
  };
  const CourseStatus = ["未开课", "已开课", "已结课"];
  let { enrollText, courseText } = parseTime(props.value);
  return (
    <div className="Course_time">
      {enrollText && (
        <li>
          <span className="yuan"></span>报名时间： {enrollText}
        </li>
      )}
      {courseText && (
        <li>
          <span className="yuan"></span>上课时间： {courseText}
        </li>
      )}
      {
        <li>
          <span className="yuan"></span>
          {currentStepNumber !== 0
            ? `活动剩余名额： ${remainingEnrolmentNumber}`
            : enrollStatus === 3 || enrollStatus === 2 || quota !== 9999999
            ? `剩余名额： ${remainingEnrolmentNumber}`
            : "名额不限"}
        </li>
      }
      {selectShow ? (
        <li onMouseOver={get_course_list} className="some_course_parent ">
          <div
            className="yuan"
            style={{ background: "rgba(119, 158, 185, 1)" }}
          ></div>
          <div className="some_course_active">
            <div
              style={{
                display: "inline-block",
                minWidth: "130px",
                maxWidth: "320px",
                marginRight: "5px",
              }}
            >
              {" "}
              {seriesName}-{name}{" "}
            </div>{" "}
            {enrollStatusText[enrollStatus]} | {CourseStatus[courseStatus]}{" "}
            <b>
              <Icon type="caret-down" />
            </b>
          </div>
          {visible === true && (
            <div className="some_course">
              {course_series.map((i) => {
                return (
                  <div
                    className="some_course_list"
                    key={i.code}
                    onClick={() => {
                      window.location.href = `/home/course/${i.code}`;
                    }}
                  >
                    <div className="some_course_title">
                      <div className="course_name">{i.name}</div>
                      <div className="course_type">
                        {enrollStatusText[i.enrollStatus]} |{" "}
                        {CourseStatus[i.courseStatus]}
                      </div>
                    </div>
                    <div className="iconList">
                      {i.enrollStatus === 5 && (
                        <span
                          className="small_fcodeIcon"
                          style={{ backgroundColor: "#2ab442" }}
                        >
                          可使用F码报名
                        </span>
                      )}
                      {i.availableCoupon && (
                        <span
                          className="small_fcodeIcon"
                          style={{ backgroundColor: "rgb(197, 76, 28)" }}
                        >
                          优惠券
                        </span>
                      )}
                    </div>
                    <div className="some_course_title">
                      <div className="flex_between">
                        <div>上课时间：</div>
                        <div>{i.startTime}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </li>
      ) : (
        <></>
      )}
      <li>
        <Icon
          onClick={() => {
            if (!loggedOn) {
              props.setPath("login");
              return;
            }
            if (collected) {
              deleteMycollect();
            } else {
              joinMycollect();
            }
          }}
          type="star"
          theme={collected ? "filled" : ""}
          style={{ color: "yellow", fontSize: "30px" }}
          className="pointer"
        />
      </li>
    </div>
  );
};

const TabComponent = (props) => {
  const [index, setIndex] = useState(0);

  const { _details, details } = props;

  return (
    <div className="course_content">
      {_details.length > 0 && (
        <div className="contentLeft">
          <div className="course_header">
            {_details.map((i, d) => {
              return (
                <div
                  key={i.tagName + d}
                  className={d === index ? "course_header_active" : ""}
                  onClick={() => {
                    setIndex(d);
                  }}
                >
                  {i.tagName}
                </div>
              );
            })}
          </div>
          {details.map((i, j) => (
            <pre
              id="prevs"
              key={j}
              style={{
                display: index === j ? "block" : "none",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                overflow: "hidden",
                width: "1042px",
                fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
                lineHeight: "20px",
              }}
            >
              <div
                className="braft-output-content"
                style={{ color: "#fff" }}
                dangerouslySetInnerHTML={{ __html: i.content }}
              ></div>
            </pre>
          ))}
        </div>
      )}
    </div>
  );
};

const QQgroupComponent = (props) => {
  let [qqNumber, setNumber] = useState("");
  const [hasError, setError] = useState(false);
  let {
    qqGroup,
    editQQnumber,
    setRemind,
    remindKey,
    remindMessage,
    isReadRemind,
  } = props;
  qqGroup = qqGroup || {};
  const submitQQ = () => {
    if (qqNumber.length === 0) {
      setError(true);
      return;
    }
    if (qqNumber.length > 13 || /[^0-9]/g.test(qqNumber)) {
      message.destroy();
      message.error("格式错误，请检查后提交");
    } else {
      Modal.confirm({
        content: (
          <span style={{ color: "#fff" }}>
            请确认进群的QQ号为:
            {<span style={{ color: "yellow" }}>{qqNumber}</span>}
          </span>
        ),
        onOk: () => {
          editQQnumber({ qqNumber: qqNumber * 1 });
        },
      });
    }
  };
  return (
    <div
      style={{ textAlign: "left" }}
      className={`course_right_box ${isReadRemind && "remindMask"}`}
    >
      {!props.qqNumber && (
        <div style={{ textAlign: "left" }}>
          <span className="radius">1</span>
          <span>请加入班级QQ群</span>
        </div>
      )}
      <div style={{ marginLeft: !props.qqNumber && "27px" }}>
        <div
          className="flex_start"
          style={{ width: "100%", alignItems: "initial" }}
        >
          <div style={{ flex: "0 0 80px" }}>QQ群名称：</div>
          <div style={{ wordWrap: "break-word", wordBreak: "break-all" }}>
            {qqGroup.name}
          </div>
        </div>
        <div className="flex_start">
          <div>QQ群号：</div>
          <div>{qqGroup.groupNumber}</div>
        </div>
      </div>
      <div>
        {qqGroup.QRCodeUrl && (
          <img
            src={qqGroup.QRCodeUrl}
            alt="qrcode"
            style={{
              width: "80%",
              height: "80%",
              margin: "15px auto",
              display: "block",
            }}
          />
        )}
      </div>
      {!props.qqNumber && (
        <div style={{ textAlign: "left", marginTop: "20px" }} className="left">
          <span className="radius">2</span>
          <span>
            您加群的<span style={{ color: "yellow" }}>个人QQ号</span>
          </span>
        </div>
      )}
      {props.qqNumber ? (
        <div className="course_right_weixindiv" style={{ width: "100%" }}>
          <div>您进群的QQ号为：</div>
          <div style={{ color: "yellow" }}>{props.qqNumber}</div>
        </div>
      ) : (
        <div className="center">
          <input
            disabled={!isReadRemind ? false : true}
            style={{ marginRight: "10px", padding: "0 5px", width: "145px" }}
            maxLength={13}
            onChange={(e) => {
              setError(false);
              setNumber(e.target.value);
            }}
            className="qqGroup_ipt"
          />
          <button
            onClick={() => !isReadRemind && submitQQ()}
            className={`bt_blue ${isReadRemind && "cursor_none"}`}
            style={{ width: "48px", lineHeight: "32px" }}
          >
            确认
          </button>
        </div>
      )}
      {hasError && (
        <div style={{ color: "red", marginLeft: "31px", marginTop: "2px" }}>
          请输入QQ号
        </div>
      )}
      {!props.qqNumber && (
        <section className="course_info myCourseInfoIcon"></section>
      )}
      {!props.qqNumber && isReadRemind && (
        <RemindComponent
          setRemind={setRemind}
          remindKey={remindKey}
          remindMessage={remindMessage}
        />
      )}
    </div>
  );
};

const ShowModal = (props) => {
  const Iframe = useRef();

  const receiveMessage = useCallback(() => {
    let { orderNumber } = props;

    window.onmessage = (msg) => {
      if (msg?.data?.source) return;
      if (msg.data.code !== 0) {
        if (msg.data.code === 5) {
          setTimeout(() => {
            localStorage.removeItem("jwt");
            window.location.href = "/";
          }, 1);
        } else {
          message.destroy();
          message.error(msg.data.message);
        }
      } else {
        Axios.put(`/enrolment_forms/${orderNumber}/1`).then((res) => {
          if (res.data.code !== 0) {
            message.destroy();
            message.error(res.data.message);
          } else {
            message.success("提交成功");
            props.reload_fn();
            props.close();
          }
        });
      }
    };
  }, [props]);

  useEffect(() => {
    ShowMask({ body: "hidden" });
    receiveMessage();
    return () => {
      window.onmessage = false;
      HiddenMask({ body: "" });
    };
  }, [receiveMessage]);
  let { popup } = props;

  const Onload = async () => {
    let { courseId, orderNumber, courseCode } = props;
    try {
      let [personInfo, courseInfo] = await Promise.all([
        getPersonInfo(),
        getCourseDetail(courseCode),
      ]);
      let token = localStorage.jwt;
      let { id, qqNumber, nickname } = personInfo.data;
      let { seriesName, name } = courseInfo.data;
      personInfo.data.qqNumber = qqNumber || undefined;
      personInfo.data.nickname = nickname || undefined;
      Iframe.current.contentWindow.postMessage(
        {
          type: 1,
          userId: id,
          token,
          filename: `${seriesName}-${name}`,
          personInfo: personInfo.data,
          courseId,
          orderNumber,
        },
        "*"
      );
    } catch (err) {
      throw err;
    }
  };
  return (
    <div className="form_iframe">
      <iframe
        loading="lazy"
        onLoad={Onload}
        ref={Iframe}
        width="750px"
        height="600px"
        title="报名表"
        src={`${formLocation}/preview/${
          props.formId
        }?${new Date().getTime()}&&token=${localStorage.jwt}`}
      ></iframe>
      {popup !== 1 ? (
        <Icon type="close" className="form_close" onClick={props.close} />
      ) : (
        ""
      )}
      {/* <div
        style={{
          width: "750px",
          height: "600px",
          display: loading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Icon type="loading" style={{ fontSize: "26px" }} />
      </div> */}
    </div>
  );
};
