import React, { useEffect, useState } from "react";

import { NavLink, Link } from "react-router-dom";

import { subscribe, getState } from "./Store";

import Login from "./../admin/login";

import { Icon } from "antd";
import store from "lib/redux/store";

let User = (props) => {
  return (
    <svg
      {...props}
      t="1572601729806"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="3262"
      width="1em"
      height="1em"
    >
      <path
        d="M515.478588 14.230588c-274.025412 0-496.941176 222.945882-496.941176 496.941176 0 274.010353 222.915765 496.926118 496.941176 496.926118 273.980235 0 496.926118-222.915765 496.926118-496.926118C1012.404706 237.176471 789.473882 14.230588 515.478588 14.230588zM801.942588 878.742588c-14.652235-145.182118-137.532235-258.846118-286.479059-258.846118-148.946824 0-271.826824 113.679059-286.464 258.846118C119.627294 793.313882 49.136941 660.374588 49.136941 511.171765c0-257.144471 209.212235-466.386824 466.356706-466.386824 257.129412 0 466.341647 209.242353 466.341647 466.386824C981.820235 660.374588 911.329882 793.344 801.942588 878.742588z"
        p-id="3263"
      ></path>
      <path
        d="M511.713882 241.829647c-89.765647 0-162.816 73.065412-162.816 162.816s73.065412 162.800941 162.816 162.800941c89.720471 0 162.785882-73.035294 162.785882-162.800941S601.434353 241.829647 511.713882 241.829647z"
        p-id="3264"
        data-spm-anchor-id="a313x.7781069.0.i2"
      ></path>
    </svg>
  );
};
const UserIcon = (props) => <Icon component={User} {...props} />;

const App = (props) => {
  const [data, setData] = useState({ userData: { avatar: "" }, path: "" });
  const [isRead, setIsRead] = useState(false);
  const setPath = (path) => {
    setData((state) => ({ ...state, path }));
  };
  useEffect(() => {
    const reloadFn = () => {
      let userData = getState();
      setData((res) => ({ ...res, userData }));
    };
    const unlisten = subscribe(reloadFn);
    reloadFn();
    return () => {
      unlisten();
    };
  }, []);
  useEffect(() => {
    store.subscribe(() => {
      let { isRead } = store.getState();
      if (isRead) {
        setIsRead(true);
      }
    });
  }, []);

  const isCouponIndex = props.location.pathname.split("/").indexOf("coupons");
  const hasCouponNum =
    data.userData.unViewedCouponNum > 0
      ? isCouponIndex != -1
        ? false
        : !isRead
        ? true
        : false
      : false;
  let avatar = data.userData.avatar!==''
    ? data.userData.avatar
    : require("./../../public/no_head__img.png");


    
  // var token = localStorage.getItem("jwt");
  return (
    <div className="_home_headers">
      <Login path={data.path} isCourse={true} setPath={setPath} {...props} />
      <div className="_home_header">
        <Link to="/home/mycourses">
          <div className="nav_logo nav_img"></div>
        </Link>
        <NavLink
          to="/home/mycourses"
          activeClassName="active"
          className="nav_tag "
        >
          <div>我的课程</div>
        </NavLink>
        <NavLink
          to="/home/mycollect"
          activeClassName="active"
          className="nav_tag"
        >
          {" "}
          <div>我的收藏</div>
        </NavLink>
        <NavLink
          to="/home/coursework"
          activeClassName="active"
          className="nav_tag"
        >
          {" "}
          <div>我的作业</div>
        </NavLink>
        {data.userData.userType === 2 ? (
          <NavLink
            to="/home/assistantswork"
            activeClassName="active"
            className="nav_tag"
          >
            {" "}
            <div>我是助教</div>
          </NavLink>
        ) : (
          ""
        )}
        <NavLink
          to="/home/profile"
          activeClassName="active"
          className="nav_tag"
        >
          {" "}
          <div style={{ position: "relative" }}>
            我的账户<div className={hasCouponNum ? "notionred" : ""}></div>
          </div>
        </NavLink>
        <NavLink
          to="/home/help/about"
          activeClassName="active"
          className="nav_tag"
        >
          {" "}
          <div>帮助中心</div>
        </NavLink>
        {data.userData.id ? (
          <div className="home_right">
            <div className="profile_list">
              <ul>
                <Link to="/home/profile">
                  <li>个人资料</li>
                </Link>
                <Link to="/home/profile/security">
                  <li>安全设置</li>
                </Link>
                <Link to="/home/profile/myorder">
                  <li>我的订单</li>
                </Link>
                <Link to="/home/profile/coupons">
                  <li style={{ position: "relative" }}>
                    我的优惠券
                    <div className={hasCouponNum ? "notionredhover" : ""}></div>
                  </li>
                </Link>
                <Link to="/home/profile/fcodes">
                  <li>我的F码</li>
                </Link>
                <Link to="/home/profile/authorith">
                  <li>认证中心</li>
                </Link>
                <Link to="/redeem">
                  <li>兑换中心</li>
                </Link>
                <Link to="/home/profile/logout">
                  {" "}
                  <li>退出登录</li>
                </Link>
              </ul>
            </div>
            <Link
              className="profile_icon"
              style={{ color: "rgba(102, 102, 102, 1)" }}
              to="/home/profile"
            >
              <div
                className="userIcon"
                style={{ backgroundImage: `url(${avatar})` }}
              ></div>
              <div className="user_icon">
                <Icon type="caret-down" style={{ color: "#fff" }} />
              </div>
            </Link>
          </div>
        ) : (
          <div className="nav_header">
            <div className="header_login_bt">
              <section>
                <span
                  style={{ color: "rgba(118, 157, 185, 1)" }}
                  className="pointer"
                  onClick={() => {
                    setPath("login");
                  }}
                >
                  {" "}
                  <UserIcon style={{ fontSize: "21px", marginRight: "8px" }} />
                  <span className="header_login_bt_text">登录</span>
                </span>
              </section>
            </div>
            <div className="header_register_bt">
              <span
                style={{ color: "rgba(118, 157, 184, 1)" }}
                className="pointer"
                onClick={() => {
                  setPath("register");
                }}
              >
                <Icon
                  style={{
                    fontSize: "20px",
                    color: "rgba(118, 157, 184, 1)",
                    marginRight: "8px",
                  }}
                  type="plus-circle"
                  theme="filled"
                />
                <span className="header_login_bt_text">注册</span>
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
